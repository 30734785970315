<template>
  <div class="mb-4">
    <div class="half-row">
      <AppInput
        v-model="role.job_title"
        :label="$t('cr-job-title')"
        :placeholder="$t('cr-job-title-plholder')"
        field="job_title"
        :errors="errors"
      />
      <AppGroupSelect
        v-model="role.skills"
        :options="skills ? skills : []"
        :placeholder="$t('cr-skills-plholder')"
        :labelText="$t('cr-skills')"
        :searchParam="'skills/getAllSkills'"
        label="name"
        trackBy="name"
        field="skills"
        :errors="errors"
        :allow-empty="true"
      />
    </div>
    <div class="half-row">
      <AppSelect
        v-model="role.state"
        :options="states ? states : []"
        :multiple="false"
        :allowEmpty="true"
        :label="$t('cr-job-location')"
        :placeholder="$t('cr-job-location-plholder')"
        field="state_id"
        :errors="errors"
      />
      <AppRadioButton
        v-model="role.work_experience"
        :selected="role.work_experience"
        :items="experience ? experience.map((exp) => {return {label: $t(exp.label), value: exp.value};}) : []"
        :groupLabel="$t('cr-experience')"
        name="work_experience"
        field="work_experience"
        :errors="errors"
      />
    </div>
    <div class="half-row">
      <div class="currency-row">
        <label>{{ $t('rate') }}</label>
        <div class="half-row w-75">
          <div class="w-25 d-flex">
            <AppInput
              v-model="role.hourly_rate_from"
              :label="$t('cr-rate-from')"
              type="number"
              field="from"
              min="0"
              :placeholder="$t('cr-from')"
              @input="reCalculate"
              :errors="errors"
              :currency="currency"
            />
            <div class="form-group">
              <label>&nbsp;</label>
              <div class="currency pt-2 pl-1">
                /H
              </div>
            </div>
          </div>
          <div class="w-25 d-flex">
            <AppInput
              v-model="role.hourly_rate_to"
              :label="$t('cr-rate-to')"
              type="number"
              field="to"
              min="0"
              :placeholder="$t('cr-to')"
              @input="reCalculate"
              :errors="errors"
              :currency="currency"
            />
            <div class="form-group">
              <label>&nbsp;</label>
              <div class="currency pt-2 pl-1">
                /H
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>&nbsp;</label>
          <div class="currency-calculate-m">
            {{
              $t("calculate-f", {
                label: monthlyRateFrom ? monthlyRateFrom : "...",
                currency: currency
              })
            }}
          </div>
          <div class="currency-calculate-m">
            {{
              $t("calculate-t", {
                label: monthlyRateTo ? monthlyRateTo : "...",
                currency: currency
              })
            }}
          </div>
        </div>
      </div>
      <div class="d-flex direction-column">
        <div class="half-row date-row mt-3">
          <AppDatepicker
            :id="$t('role')"
            class="a-form-group w-100 input-white"
            v-model="role.start_date"
            :label="$t('cr-start-date')"
            :labelClass="true"
            :limitFrom="roleMinDate"
            :limitTo="dateLimitTo"
            field="start_date"
            :errors="errors"
          />
          <AppDatepicker
            :id="$t('role')"
            class="a-form-group w-100 input-white"
            v-model="role.end_date"
            :label="$t('cr-end-date')"
            :labelClass="true"
            :limitFrom="roleMinDate"
            :limitTo="dateLimitTo"
            field="end_date"
            :errors="errors"
          />
        </div>
        <AppGroupSelect
          v-model="role.personality_type"
          :options="personalityTypes ? personalityTypes : []"
          :multiple="false"
          :placeholder="$t('cr-personality-plholder')"
          :labelText="$t('cr-personality')"
          label="label"
          groupLabel="type"
          groupValues="libs"
          trackBy="label"
          field="personality_type"
          :errors="errors"
        />
      </div>
    </div>
    <div class="half-row">
      <div v-if="isMobile">
        <DaysInWeekMobile
          class="desired-row"
          :desired_availability="role.desired_availability"
          :errors="errors"
          field="desired_availability"
        />
      </div>
      <div v-else>
        <DaysInWeek
          class="desired-row"
          :desired_availability="role.desired_availability"
          :errors="errors"
          field="desired_availability"
        />
      </div>
    </div>
    <AppEditor
      v-model="role.description"
      :label="$t('cr-description')"
      field="description"
      :optional="true"
      :errors="errors"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppInput from '../forms/AppInput';
import AppGroupSelect from '../forms/AppGroupSelect';
import AppSelect from '../forms/AppSelect';
import AppDatepicker from '../forms/AppDatepicker';
import AppEditor from '../forms/AppEditor';
import AppRadioButton from '../forms/AppRadioButton';
import DaysInWeekMobile from '../forms/DaysInWeekMobile';
import DaysInWeek from '../forms/DaysInWeek';
import { mobileAndTabletCheck } from '../../utils/helpers';


export default {
  name: 'role-template-assignment',
  i18nOptions: { namespaces: 'role-modal' },
  components: {
    AppInput,
    AppGroupSelect,
    AppSelect,
    AppDatepicker,
    AppEditor,
    AppRadioButton,
    DaysInWeekMobile,
    DaysInWeek,
  },
  data() {
    return {
      limitTo: null,
      currency: this.$appConfig.currency,
      experience: [
        { label: this.$t('any'), value: 0 },
        { label: `< 1 ${this.$t('year')}`, value: 1 },
        { label: `> 1 ${this.$t('year')}`, value: 2 },
        { label: `> 2 ${this.$t('years')}`, value: 3 },
        { label: `> 3 ${this.$t('years')}`, value: 4 },
        { label: `> 5 ${this.$t('years')}`, value: 5 },
      ],
      rate: [{ label: 'Hours', value: 0 }, { label: 'Month', value: 1 }],
      isMobile: mobileAndTabletCheck(),
    };
  },
  props: ['role', 'roleMinDate', 'dateLimitTo', 'monthlyRateFrom', 'monthlyRateTo', 'errors'],
  computed: {
    ...mapState({
      skills: state => state.skills.skills,
      states: state => state.countries.states,
      // personalityTypes: state => state.personalityTypes.all,
    }),
    personalityTypes() {
      return this.$store.getters['personalityTypes/getPersonalityTypesByRole'];
    },
  },
  methods: {
    updateProps(props) {
      this.$store.commit('roles/roleProps', props);
    },
    reCalculate() {
      this.$emit('reCalculate');
    },
  },
};
</script>
